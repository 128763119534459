import React, { FunctionComponent } from "react"
import styled from "styled-components"
import CompassRose from "../Icons/CompassRose"
import { motion } from "framer-motion"

interface IMenuToggleProps {
  isToggled?: boolean
  toggle: () => any
}

const MenuToggle: FunctionComponent<IMenuToggleProps> = ({ toggle }) => (
  <AnimatedMenuToggle
    onClick={toggle}
    whileHover={{
      // rotate: -45,
      scale: 1.1,
    }}
    whileTap={{
      // rotate: -90,
      scale: 0.9,
    }}
    variants={{
      closed: {
        rotate: 0,
      },
      open: {
        rotate: 90,
      },
    }}
  >
    <CompassRose />
    {/* <svg width="25" height="32" viewBox="0 4 22 9">
      <Path
        variants={{
          closed: {
            d: "M 2 2.5 L 20 2.5",
            stroke: "hsl(0, 0%, 10%)",
            transition: closedTransition,
          },
          open: {
            d: "M 3 16.5 L 17 2.5",
            stroke: "hsl(0, 0%, 90%)",
            transition: openTransition,
          },
        }}
      />
      <Path
        d="M 2 9.423 L 20 9.423"
        variants={{
          closed: {
            opacity: 1,
            stroke: "hsl(0, 0%, 10%)",
            transition: closedTransition,
          },
          open: {
            opacity: 0,
            stroke: "hsl(0, 0%, 90%)",
            transition: openTransition,
          },
        }}
      />
      <Path
        variants={{
          closed: {
            d: "M 2 16.346 L 20 16.346",
            stroke: "hsl(0, 0%, 10%)",
            transition: closedTransition,
          },
          open: {
            d: "M 3 2.5 L 17 16.346",
            stroke: "hsl(0, 0%, 90%)",
            transition: openTransition,
          },
        }}
      />
    </svg> */}
  </AnimatedMenuToggle>
)

const AnimatedMenuToggle = styled(motion.button)`
  outline: none;
  border: none;
  cursor: pointer;
  padding: 0;

  position: fixed;
  top: 50px;
  right: 50px;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: transparent;
`

export default MenuToggle
