import React, { FunctionComponent } from "react"
import { motion, Variants, useCycle } from "framer-motion"
import styled from "styled-components"
import { Link } from "gatsby"

const menuItem: Variants = {
  open: {
    y: 0,
    opacity: 1,
    transition: {
      y: { stiffness: 1000, velocity: -100 },
    },
  },
  closed: {
    y: 50,
    opacity: 0,
    transition: {
      y: { stiffness: 1000 },
    },
  },
}

const linkTransition = {
  type: "spring",
  stiffness: 200,
  damping: 100,
}

const LinkContainer = styled(motion.div)`
  border-radius: 4px;
  display: inline-block;
  text-align: center;
  text-transform: uppercase;

  background-image: linear-gradient(
    120deg,
    transparent 0%,
    transparent 50%,
    #fff 50%
  );
  background-size: 250%;

  a:link,
  a:visited {
    color: #fff;
    font-size: 30px;
    font-weight: 400;
    text-decoration: none;
  }
`

const StyledLink = styled(Link)`
  display: inline-block;
  padding: 20px 40px;
`

interface IProps {
  to: string
  toggle: () => void
}

const MenuItem: FunctionComponent<IProps> = ({ children, to, toggle }) => {
  const [isHover, toggleIsHover] = useCycle(false, true)

  return (
    <AnimatedMenuItem variants={menuItem}>
      <LinkContainer
        whileHover={{
          backgroundPosition: `100%`,
        }}
        whileTap={{
          y: `5px`,
        }}
        onHoverStart={() => toggleIsHover()}
        onHoverEnd={() => toggleIsHover()}
        transition={linkTransition}
        onClick={toggle}
      >
        <StyledLink to={to}>
          <motion.span
            animate={{
              color: isHover ? `#000` : `#fff`,
            }}
            transition={linkTransition}
          >
            {children}
          </motion.span>
        </StyledLink>
      </LinkContainer>
    </AnimatedMenuItem>
  )
}

const AnimatedMenuItem = styled(motion.li)`
  list-style: none;
  margin: 10px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;

  display: flex;
  justify-content: space-around;
`

export default MenuItem
