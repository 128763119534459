import React, { FunctionComponent } from "react"
import {
  motion,
  SVGMotionProps,
  // Variants,
  // Transition
} from "framer-motion"

/* const closedTransition: Transition = {
  delay: 0.5,
  type: `spring`,
  stiffness: 200,
  damping: 100,
}

const openTransition: Transition = {
  type: `spring`,
  stiffness: 100,
  damping: 100,
} */

/* const darkVariation: Variants = {
  closed: {
    fill: `#0b1118`,
    transition: closedTransition,
  },
  open: {
    fill: `#f1f1f1`,
    transition: openTransition,
  },
}

const lightVariation: Variants = {
  closed: {
    fill: `#f1f1f1`,
    transition: closedTransition,
  },
  open: {
    fill: `#0b1118`,
    transition: openTransition,
  },
}

const centerVariation: Variants = {
  closed: {
    fill: `#f1f1f1`,
    stroke: `#0b1118`,
    transition: closedTransition,
  },
  open: {
    fill: `#0b1118`,
    stroke: `#f1f1f1`,
    transition: openTransition,
  },
} */

const Path: FunctionComponent<SVGMotionProps<SVGPathElement>> = props => (
  <motion.path {...props} />
)

interface IProps {
  className?: string
}

const CompassRose: FunctionComponent<IProps> = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="Layer_1"
      data-name="Layer 1"
      viewBox="0 0 63.16 63.16"
      className={className}
    >
      <Path
        d="M48.21 14.95l.41.41 1.01-1.41 1-1.42-1.41 1.01-1.42 1 .41.41zM45.43 12.59l.47.34.76-1.56.77-1.56-1.23 1.22-1.24 1.22.47.34zM54.84 27.74l.09.57 1.64-.56 1.64-.56-1.73-.01-1.74-.01.1.57zM50.85 45.14l-.34.47 1.56.76 1.56.77-1.22-1.24-1.22-1.23-.34.47zM42.22 10.64l.52.26.5-1.65.51-1.66-1.02 1.4-1.03 1.4.52.25zM52.77 41.91l-.26.52 1.66.51 1.66.5-1.41-1.02-1.39-1.03-.26.52zM38.84 9.27l.56.18.24-1.72.24-1.72-.79 1.54-.8 1.55.55.17zM52.49 20.75l.27.51 1.39-1.04 1.38-1.04-1.65.52-1.65.53.26.52zM35.27 8.43l.57.09-.03-1.73-.03-1.74-.54 1.65-.54 1.65.57.08zM50.54 17.64l.35.46 1.2-1.24 1.21-1.25-1.55.78-1.55.78.34.47zM54.92 34.94l-.09.57 1.73-.03 1.74-.02-1.65-.55-1.64-.54-.09.57zM35.5 54.74l.57-.1-.01 1.74-.01 1.73-.56-1.64-.57-1.64.58-.09zM38.94 53.9l.55-.18.25 1.72.26 1.71-.8-1.54-.81-1.53.55-.18z"
        // style={{ fill: isToggled ? `#f1f1f1` : `#0b1118` }}
        style={{ fill: `#f1f1f1` }}
        // variants={darkVariation}
      />
      <Path
        d="M38.94 53.9l.55-.18.25 1.72.26 1.71-.8-1.54-.81-1.53.55-.18zM42.39 52.46l.52-.26.52 1.65.52 1.65-1.04-1.39-1.04-1.39.52-.26z"
        // variants={darkVariation}
        style={{ fill: `#f1f1f1` }}
      />
      <Path
        d="M42.39 52.46l.52-.26.52 1.65.52 1.65-1.04-1.39-1.04-1.39.52-.26zM45.55 50.5l.46-.34.78 1.56.77 1.55-1.24-1.22-1.24-1.2.47-.35zM48.32 48.1l.41-.41 1.01 1.41 1 1.42-1.41-1-1.42-1.01.41-.41zM14.95 14.95l-.41.41-1.01-1.41-1-1.42 1.41 1.01 1.42 1-.41.41zM17.73 12.59l-.46.34-.77-1.56-.77-1.56 1.24 1.22 1.23 1.22-.47.34zM8.32 27.74l-.09.57-1.64-.56-1.64-.56 1.73-.01 1.74-.01-.1.57zM12.31 45.14l.34.47-1.55.76-1.56.77 1.22-1.24 1.21-1.23.34.47zM20.95 10.64l-.52.26-.51-1.65-.51-1.66 1.03 1.4 1.02 1.4-.51.25zM10.4 41.91l.26.52-1.66.51-1.66.5 1.4-1.02 1.4-1.03.26.52zM24.32 9.27l-.55.18-.24-1.72-.25-1.72.8 1.54.79 1.55-.55.17zM10.67 20.75l-.27.51-1.38-1.04-1.39-1.04 1.65.52 1.66.53-.27.52zM27.9 8.43l-.58.09.03-1.73.03-1.74.54 1.65.55 1.65-.57.08zM12.62 17.64l-.34.46-1.21-1.24-1.21-1.25 1.55.78 1.55.78-.34.47zM8.24 34.94l.09.57-1.73-.03-1.73-.02 1.64-.55 1.65-.54.08.57zM27.67 54.74l-.58-.1.02 1.74v1.73l.57-1.64.56-1.64-.57-.09zM24.22 53.9l-.55-.18-.25 1.72-.25 1.71.8-1.54.8-1.53-.55-.18z"
        // variants={darkVariation}
        style={{ fill: `#f1f1f1` }}
      />
      <Path
        d="M24.22 53.9l-.55-.18-.25 1.72-.25 1.71.8-1.54.8-1.53-.55-.18zM20.77 52.46l-.51-.26-.52 1.65-.52 1.65 1.03-1.39 1.04-1.39-.52-.26z"
        // variants={darkVariation}
        style={{ fill: `#f1f1f1` }}
      />
      <Path
        d="M20.77 52.46l-.51-.26-.52 1.65-.52 1.65 1.03-1.39 1.04-1.39-.52-.26zM17.62 50.5l-.47-.34-.78 1.56-.77 1.55 1.24-1.22 1.25-1.2-.47-.35zM14.84 48.1l-.41-.41-1 1.41-1.01 1.42 1.41-1 1.42-1.01-.41-.41zM53.91 24.11l.17.55 1.55-.78 1.55-.77-1.72.22-1.72.22.17.56zM54.12 38.52l-.17.56 1.72.22 1.72.22-1.55-.78-1.55-.77-.17.55zM9.25 24.11l-.17.55-1.55-.78-1.55-.77 1.72.22 1.72.22-.17.56zM9.04 38.52l.17.56-1.72.22-1.72.22 1.55-.78 1.55-.77.17.55z"
        // variants={darkVariation}
        style={{ fill: `#f1f1f1` }}
      />
      <Path
        d="M31.58 53.91a22.33 22.33 0 1122.33-22.33 22.36 22.36 0 01-22.33 22.33zm0-40.17a17.84 17.84 0 1017.84 17.84 17.86 17.86 0 00-17.84-17.84z"
        // variants={darkVariation}
        style={{ fill: `#f1f1f1` }}
      />
      <Path
        d="M31.58 46.91a15.33 15.33 0 1115.33-15.33 15.34 15.34 0 01-15.33 15.33zm0-29.68a14.36 14.36 0 1014.36 14.35 14.37 14.37 0 00-14.36-14.35z"
        // variants={darkVariation}
        style={{ fill: `#f1f1f1` }}
      />
      <Path
        d="M61.03 31.58l-23.04 3.94-6.41-3.94 6.4-3.94 23.05 3.94z"
        // variants={darkVariation}
        style={{ fill: `#f1f1f1` }}
      />
      <Path
        d="M58.3 31.58H32.45l5.62-3.46 20.23 3.46z"
        // style={{ fill: isToggled ? `#0b1118` : `#f1f1f1` }}
        // variants={lightVariation}
        style={{ fill: `#0b1118` }}
      />
      <Path
        d="M2.13 31.58l23.04-3.94 6.41 3.94-6.4 3.94-23.05-3.94z"
        // variants={darkVariation}
        style={{ fill: `#f1f1f1` }}
      />
      <Path
        d="M4.86 31.58h25.86l-5.62 3.46-20.24-3.46z"
        // variants={lightVariation}
        style={{ fill: `#0b1118` }}
      />
      <Path
        d="M31.58 2.13l3.94 23.04-3.94 6.41-3.94-6.4 3.94-23.05z"
        // variants={darkVariation}
        style={{ fill: `#f1f1f1` }}
      />
      <Path
        d="M31.58 4.86v25.86l-3.46-5.63 3.46-20.23z"
        // variants={lightVariation}
        style={{ fill: `#0b1118` }}
      />
      <Path
        d="M31.58 61.03l-3.94-23.04 3.94-6.41 3.94 6.4-3.94 23.05z"
        // variants={darkVariation}
        style={{ fill: `#f1f1f1` }}
      />
      <Path
        d="M31.58 58.3V32.45l3.46 5.62-3.46 20.23z"
        // variants={lightVariation}
        style={{ fill: `#0b1118` }}
      />
      <Path
        d="M35.17 31.58A3.59 3.59 0 1131.58 28a3.59 3.59 0 013.59 3.58z"
        /* style={{
          fill: isToggled ? `#0b1118` : `#f1f1f1`,
          stroke: isToggled ? `#f1f1f1` : `#0b1118`,
        }} */
        // variants={centerVariation}
        style={{
          fill: `#0b1118`,
          stroke: `#f1f1f1`,
        }}
      />
      <Path
        d="M33.1 31.58a1.52 1.52 0 11-1.52-1.52 1.52 1.52 0 011.52 1.52z"
        // variants={darkVariation}
        style={{ fill: `#f1f1f1` }}
      />
    </svg>
  )
}

export default CompassRose
