/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { FunctionComponent } from "react"
// import { useStaticQuery, graphql } from "gatsby"

import "./layout.scss"
import "normalize.css"

import Footer from "./Footer"
import Navigation from "./Navigation"

const Layout: FunctionComponent = ({ children }) => {
  /* const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `) */

  return (
    <div style={{ position: "relative" }}>
      <Navigation />
      {children}
      <Footer />
    </div>
  )
}

export default Layout
