/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { FunctionComponent } from "react"
import { Helmet, MetaProps } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

interface IProps {
  description?: string
  lang?: string
  meta?: MetaProps[]
  title?: string
}

const SEO: FunctionComponent<IProps> = ({
  description = ``,
  lang = "en",
  meta = [],
  title,
}) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      meta={([
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ] as MetaProps[]).concat(meta)}
    >
      <link
        href="https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&display=swap"
        rel="stylesheet"
      />
      <link
        href="https://fonts.googleapis.com/css2?family=Noto+Serif:ital@1&display=swap"
        rel="stylesheet"
      ></link>
      {/* <link href="https://unpkg.com/sanitize.css" rel="stylesheet" /> */}
      {/* <link href="https://unpkg.com/sanitize.css/forms.css" rel="stylesheet" /> */}
      {/* <link
        href="https://unpkg.com/sanitize.css/typography.css"
        rel="stylesheet"
      /> */}
    </Helmet>
  )
}

export default SEO
