import React from "react"
import styled from "styled-components"
import CutlassSwords from "./Icons/CutlassSwords"
// import FlexList from "./FlexList"
// import { Link } from "gatsby"

const Footing = styled.footer`
  padding: 50px 0;
  color: #fff;
  background: #0f0f0f;

  *:not(:last-child) {
    margin-bottom: 20px;
  }
`

const LogoBox = styled.div`
  text-align: center;
`

const Copyright = styled.div`
  text-align: center;

  span {
    text-transform: uppercase;
  }
`

const Logo = styled(CutlassSwords)`
  width: 150px;
`

/* const StyledLink = styled(Link)`
  &:link,
  &:visited {
    text-decoration: none;
    text-transform: uppercase;
    display: inline-block;
    color: #fff;
    margin: 0 40px;
    transition: all 0.2s ease;
    padding: 10px 0;
    border-bottom: 2px solid transparent;
  }

  &:hover,
  &:active,
  &:focus {
    color: #56bda2;
    border-bottom: 2px solid #56bda2;
  }
` */

const Footer = () => {
  return (
    <Footing>
      <LogoBox>
        <Logo />
      </LogoBox>
      {/* <FlexList>
        <li>
          <StyledLink to="#">Company</StyledLink>
        </li>
        <li>
          <StyledLink to="#">Contact us</StyledLink>
        </li>
        <li>
          <StyledLink to="#">Careers</StyledLink>
        </li>
        <li>
          <StyledLink to="#">Privacy policy</StyledLink>
        </li>
        <li>
          <StyledLink to="#">Terms</StyledLink>
        </li>
      </FlexList> */}
      <Copyright>
        Copyright &copy; 2020, <span>Cutlass Design Studios</span>
      </Copyright>
    </Footing>
  )
}

export default Footer
