import React, { FunctionComponent, useRef } from "react"
import { motion, useCycle, Variants } from "framer-motion"
import styled from "styled-components"

import Menu from "./Menu"
import MenuToggle from "./MenuToggle"
import useWindowSize from "../../hooks/useWindowSize"

const sidebar: Variants = {
  open: ({ width, height }) => {
    return {
      clipPath: `circle(${
        width > height ? width * 2 : height * 2
      }px at calc(100% - 80px) 80px)`,
      // background: `hsl(0, 0%, 6%)`,
      transition: {
        type: `spring`,
        stiffness: 50,
        damping: 100,
        restDelta: 2,
        restSpeed: 0.01,
      },
    }
  },
  closed: {
    clipPath: `circle(30px at calc(100% - 80px) 80px)`,
    // background: `hsl(0, 0%, 90%)`,
    transition: {
      delay: 0.2,
      type: `spring`,
      stiffness: 150,
      damping: 100,
      restDelta: 0.01,
      restSpeed: 0.001,
    },
  },
}

const Background: FunctionComponent<{
  dimensions: { width: number; height: number }
}> = ({ dimensions }) => (
  <AnimatedBackground variants={sidebar} custom={dimensions} />
)

const AnimatedBackground = styled(motion.div)`
  width: 100%;
  pointer-events: none;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  background: hsl(0, 0%, 6%);
`

const Navigation: FunctionComponent = () => {
  const [isOpen, toggleOpen] = useCycle(false, true)
  const containerRef = useRef<HTMLElement>(null)

  const dimensions = useWindowSize()

  return (
    <AnimatedNavigation
      initial={false}
      animate={isOpen ? "open" : "closed"}
      ref={containerRef}
    >
      <Background dimensions={dimensions} />
      <Menu isOpen={isOpen} toggle={() => toggleOpen()} />
      <MenuToggle toggle={() => toggleOpen()} />
    </AnimatedNavigation>
  )
}

const AnimatedNavigation = styled(motion.nav)`
  z-index: 1000;
  position: relative;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
`

export default Navigation
