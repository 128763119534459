import React, { FunctionComponent } from "react"
import { motion, Variants } from "framer-motion"

import MenuItem from "./MenuItem"
import styled from "styled-components"

const navigation: Variants = {
  open: {
    transition: { staggerChildren: 0.03, delayChildren: 0.4 },
  },
  closed: {
    transition: { staggerChildren: 0.03, staggerDirection: -1 },
  },
}

interface IProps {
  isOpen: boolean
  toggle: () => void
}

const Menu: FunctionComponent<IProps> = ({ isOpen, toggle }) => {
  return (
    <AnimatedMenu
      variants={navigation}
      style={{ pointerEvents: isOpen ? "all" : "none" }}
    >
      <MenuItem to="#who-we-are" toggle={toggle}>
        Who We Are
      </MenuItem>
      <MenuItem to="#what-we-do" toggle={toggle}>
        What We Do
      </MenuItem>
      <MenuItem to="#contact-us" toggle={toggle}>
        Contact Us
      </MenuItem>
    </AnimatedMenu>
  )
}

const AnimatedMenu = styled(motion.ul)`
  margin: 0;
  padding: 0;
  position: fixed;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  width: 100%;
`

export default Menu
